// import http from '../http-common'

export default {
  async searchVariants(search) {
    try {
      const response = await useApi(`/api/variants?search=${search}`)
      return response
    }
    catch (error) {
      console.error(error)
      return [error]
    }
  },
  async searchProducts(search) {
    try {
      const response = await useApi(`/api/products/search?search=${search}`)
      return response
    }
    catch (error) {
      console.error(error)
      return [error]
    }
  },
  async getProductsOfGroup(groupString) {
    try {
      const response = await useApi(`/api/products?group=${groupString}`)
      return response
    }
    catch (error) {
      console.error(error)
      return [error]
    }
  },
  async getProductsOfCategory(categoryId, groupString) {
    try {
      const response = await useApi(`/api/products?category_id=${categoryId}&group=${groupString}`)
      return response
    }
    catch (error) {
      console.error(error)
      return [error]
    }
  },
  async getProduct(productId) {
    const response = useApi(`/api/products/${productId}`).then((response) => {
      return response
    }).catch((error) => {
      // console.error(error)
      return error
    })
    return response
  },

  setLanguage(language) {
    axios.defaults.headers.common.Language = language
  },
  async getCategories() {
    try {
      const response = useApi(`/api/products/categories`)
      return response
    }
    catch (error) {
      console.error(error)
      return [error]
    }
  },
}
